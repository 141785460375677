import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

export default function DropdownJLink({ to, ...props }) {
  if (to.includes("#")) {
    let firstChar = to[0]
    if (firstChar !== "/") {
      to = "/" + to
    }
  } else {
    let firstChar = to[0]
    let lastChar = to.substr(-1) // Selects the last character
    if (lastChar !== "/") {
      // If the last character is not a slash
      to = to + "/"
    }
    if (firstChar !== "/") {
      to = "/" + to
    }
  }
  return <Link {...props} to={to} />
}

DropdownJLink.propTypes = {
  to: PropTypes.string.isRequired
}
