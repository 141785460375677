import PropTypes from "prop-types"
import React from "react"
import classNames from "classnames"

import { ButtonGroupMap } from "../Button"
import { Text } from "../Core"

class MarkdownSection extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    let sectionClasses = classNames(
      "body-sections section joshua-tree-content",
      {
        "color-back": this.props.colorBack
      }
    )

    return (
      <section
        className={sectionClasses}
        style={{
          paddingTop: this.props.paddingTop,
          paddingBottom: this.props.paddingBottom
        }}>
        <div className="columns">
          <div className={`column is-${this.props.sideColumnIs}`}></div>
          <div className="column">
            <Text useStringReplace text={this.props.textSection} />

            {this.props.buttons && (
              <ButtonGroupMap isCentered buttons={this.props.buttons} />
            )}
          </div>
          <div className={`column is-${this.props.sideColumnIs}`}></div>
        </div>
      </section>
    )
  }
}

MarkdownSection.propTypes = {
  textSection: PropTypes.string.isRequired,
  sideColumnIs: PropTypes.number
}

export default MarkdownSection
